import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "useStore",
  state: () => {
    const item = localStorage.getItem("useStore");
    if (item != undefined & item != null && item !== "undefined") {
      return JSON.parse(item);
    } else {
      return {
        jwt: null,
        locale: "es",
        env: null,
        models: {},
        user: null,
        userComponents: null,
        currentTownhall: null,
        allTownhalls: [],
        projects: [],
        commandCenters: [],
        layout: {
          layoutType: "vertical",
          layoutWidth: "fluid",
          sidebarSize: "lg",
          topbar: "light",
          mode: "light",
          position: "fixed",
          sidebarView: "default",
          sidebarColor: "dark",
          sidebarImage: "none",
          preloader: "disable",
        },
        hoverd: false,
        rightbar_isopen: false,
        rmenu: "vertical",
        performIssue: null,
        currentCommandCenter: null,
        currentStreetlight: null,
        layoutDashboard: null,
        layoutDashboardEdit: null,
        editando: false,
        action: null,
        filterString: "",
        categoryFilter: null,
        activePeriod: "last_day",
        activeTab: null,
        syncronizedGraphs: true,
        dateDesdePeriod: null,
        dateHastaPeriod: null,
        currentProject: null,
        assetTypes: null,
        currentAsset: null,
        currentBuilding: null,
        objetoDatos: {},
        edicionMultipleLuminarias: null,
        lastLoggerCCIds: null,
        customListPagination : null
      };
    }
  },
  actions: {
    setJWT(jwt) {
      this.jwt = jwt;
    },
    setLocale(locale) {
      this.locale = locale;
    },
    setEnv(env) {
      this.env = env;
    },
    setModels(models) {
      this.models = models;
    },
    setAllTownhalls(allTownhalls) {
      this.allTownhalls = allTownhalls;
    },
    setProjects(projects) {
      this.projects = projects;
    },
    setCommandCenters(commandCenters) {
      this.commandCenters = commandCenters;
    },
    setUser(payload) {
      this.user = payload;
    },
    setCurrentTownhall(payload) {
      this.currentTownhall = payload;
    },
    setPerformIssue(issue) {
      this.performIssue = issue;
    },
    setCurrentCommandCenter(commandCenter) {
      this.currentCommandCenter = commandCenter;
    },
    setCurrentProject(project) {
      this.currentProject = project;
    },
    setCurrentStreetlight(streetlight) {
      this.currentStreetlight = streetlight;
    },
    setCurrentBuilding(building) {
      this.currentBuilding = building;
    },
    setCurrentAsset(asset) {
      this.currentAsset = asset;
    },
    //Layout
    changeLayoutType(layoutType) {
      this.layout.layoutType = layoutType;
    },
    changeLayoutWidth(layoutWidth) {
      this.layout.layoutWidth = layoutWidth;
    },
    changeSidebarSize(sidebarSize) {
      this.layout.sidebarSize = sidebarSize;
    },
    changeTopbar(topbar) {
      this.layout.topbar = topbar;
    },
    changeMode(mode) {
      this.layout.mode = mode;
    },
    changePosition(position) {
      this.layout.position = position;
    },
    changeSidebarView(sidebarView) {
      this.layout.sidebarView = sidebarView;
    },
    changeSidebarColor(sidebarColor) {
      this.layout.sidebarColor = sidebarColor;
    },
    changeSidebarImage(sidebarImage) {
      this.layout.sidebarImage = sidebarImage;
    },
    changePreloader(preloader) {
      this.layout.preloader = preloader;
    },
    resetStore() {
      this.$reset();
      localStorage.setItem(
        "useStore",
        JSON.stringify({
          jwt: null,
          locale: "es",
          env: null,
          models: {},
          user: null,
          userComponents: null,
          currentTownhall: null,
          allTownhalls: [],
          projects: [],
          commandCenters: [],
          layout: {
            layoutType: "vertical",
            layoutWidth: "fluid",
            sidebarSize: "lg",
            topbar: "light",
            mode: "light",
            position: "fixed",
            sidebarView: "default",
            sidebarColor: "dark",
            sidebarImage: "none",
            preloader: "disable",
          },
          hoverd: false,
          rightbar_isopen: false,
          rmenu: "vertical",
          performIssue: null,
          currentCommandCenter: null,
          currentStreetlight: null,
          currentProject: null,
          currentAsset: null,
          currentBuilding: null,
          layoutDashboard: null,
          layoutDashboardEdit: null,
          editando: false,
          action: null,
          filterString: "",
          categoryFilter: null,
          activePeriod: "last_day",
          activeTab: null,
          syncronizedGraphs: true,
          dateDesdePeriod: null,
          dateHastaPeriod: null,
          assetTypes: null,
          objetoDatos: {},
          edicionMultipleLuminarias: null,
          lastLoggerCCIds: null,
          customListPagination: null
        })
      );
    },
  },
  getters: {
    layoutType: (state) => state.layout.layoutType,
    sidebarSize: (state) => state.layout.sidebarSize,
    layoutWidth: (state) => state.layout.layoutWidth,
    topbar: (state) => state.layout.topbar,
    mode: (state) => state.layout.mode,
    position: (state) => state.layout.position,
    sidebarView: (state) => state.layout.sidebarView,
    sidebarColor: (state) => state.layout.sidebarColor,
    sidebarImage: (state) => state.layout.sidebarImage,
  },
});
