import { createApp, watch } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

import axios from 'axios'
import { helper } from './helpers/helper.js'
import "./register-service-worker"

import VueAwesomeSwiper from 'vue-awesome-swiper'

// import swiper module styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// more module style...

import { abortAxios } from "./router/network.js";
import { useStore } from './state/useStore.js';
import { cacheStore } from './state/cacheStore.js';
import localforage from '../localforage.config';
import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'
import Swal from 'sweetalert2';


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});
/** Metodo para crear toast notificatios tener en cuanta el orden de parametros
 * parametros: 
 *  typeAlert: Mediante string establece el color del contendor toast y su color de icono
 *  inconAlert: String que contiene nombre de  la clase icono 
 *  content: Texto que se muestra en el toast dentro de un elemeno <h6>
 *  error: Contiene objeto error de axios genera un elemento <a> que tiene evento click que muestra swal
 *  los detalles del error si el valor es nulo no se genera
*/
function createCustomToast(typeAlert, iconAlert, content, error) {
    let iconColor;
    let borderColor;
    switch (typeAlert) {
        case 'error':
        case 'danger':
            borderColor = 'border-danger'; // Color de borde rojo
            iconColor = 'text-danger'
            break;
        case 'warning':
            borderColor = 'border-warning'; // Color de borde amarillo
            iconColor = 'text-warning'
            break;
        case 'success':
        case 'confirm':
            borderColor = 'border-success'; // Color de borde azul
            iconColor = 'text-success'
            break;
        case 'info':
            borderColor = 'border-info'; // Color de borde azul
            iconColor = 'text-info'
            break;
        default:
            borderColor = 'border-dark'; // Color de borde gris
            iconColor = 'text-dark'
    }
    Toastify({
        // ${error.response.status?`<b>ERROR ${error.response.status}</b>: `:''}
        text: `<div class="d-flex align-items-center fs-1">
              <div class="flex-shrink-0 me-2">
                  <i class="${iconAlert} ${iconColor} align-middle"></i>
              </div>
              <div class="flex-grow-1">                                                               
                  <h6 class="m-0">                
                  ${content} 
                  ${error ? '<a id="error-details-link" @click="showErrorDetails" class="c-pointer text-decoration-underline">Ver más detalles</a>' : ''}
                  </h6>
              </div>
            </div>`,
        duration: 8000,
        close: true,
        gravity: "bottom",
        position: "right",
        stopOnFocus: true,
        escapeMarkup: false, // Permite usar HTML en el contenido
        style: {
            background: "white",
        },
        className: `d-flex align-items-center border-bottom border-5 ${borderColor} overflow-hidden custom-toast`,
        // onClick: function () {
        //   alert('Clicked on detailed report link.');
        // }
    }).showToast();
    if (error) {
        if (document.getElementById("error-details-link")) {
            document.getElementById("error-details-link").addEventListener("click", function () {

                let requestData = error.config?.data;
                try {
                    requestData = JSON.parse(requestData); // Si es JSON, se parsea
                } catch (e) {
                    // Si no es JSON, se deja el valor original
                }
                Swal.fire({
                    title: 'Detalles del error',
                    html: `<div class="text-start">
                        <strong>Status:</strong> ${error.response?.status || "Desconocido"} <br>
                        <strong>Método:</strong> ${error.config?.method || "No disponible"} <br>
                        <strong>URL:</strong> ${error.config?.url || "No disponible"} <br>
                        <strong>Datos enviados:</strong> <pre class="mb-0">${JSON.stringify(requestData, null, 2) || "No disponible"}</pre>
                        <strong>Mensaje:</strong> ${error.response?.data?.message || "No disponible"} <br>                        
                        </div>`,
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#3085d6"
                });
            });
        }
    }
}

axios.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error) {
        if (error.response) {
            if (error.response.status == 401 && error.config.url !== "https://coresitelight.endesarrollo.ovh/api/v1/scheduled-task") {
                abortAxios()
                helper.swalConfirm("t-warning", "t-lost-session", 'warning', "js")
                useStore().resetStore()
                cacheStore().resetStore()
                router.push({
                    path: '/login'
                });
            }
            if (error.response.status == 403 && !error.config.url.includes("/v2/events/")) {
                router.push({
                    path: '/'
                });
            }
            if (error.response.status == 429) {
                helper.swalConfirm("t-warning", "t-too-many-requests", 'warning', "js")
            }
            if (error.response.status == 500) {
                // muestrea toas enviendo el tipo de toast color, el icono el contenido del texto tener en cuenta 
                // si contiene campo error ultimo genera elemento a con evento click para mostrar detalles en swal                
                createCustomToast("error", "ri-alert-line", "Ha ocurrido un error con el servidor.", error)
            }
            if (error.response.status == 504) {
                // muestrea toas enviendo el tipo de toast color, el icono el contenido del texto tener en cuenta 
                // si contiene campo error ultimo genera elemento a con evento click para mostrar detalles en swal 
                createCustomToast("error", "ri-alert-line", "Ha ocurrido un error con el servidor.", error)
            }
        } else if (error.code === 'ERR_NETWORK') {
            console.log("Error al conectar con la base de Datos")
        }
        return Promise.reject(error);
    }

});
const pinia = createPinia();

watch(pinia.state, (state) => {
    const stores = Object.keys(state)
    stores.forEach(st => {
        if (st == 'useStore') {
            localStorage.setItem(st, JSON.stringify(state[st]))
        }
    })
}, { deep: true }
);

const app = createApp(App)
    .use(pinia)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(VueAwesomeSwiper)
    .use(vClickOutside)

const cache = cacheStore();
// Cargar el cache al iniciar la aplicación
cache.loadCache().then(() => {
    // Watcher para observar cambios en cache
    watch(
        () => cache.cache,
        async (newCache) => {
            try {
                const serializedCache = JSON.parse(JSON.stringify(newCache));
                await localforage.setItem('cache', serializedCache);
                // console.log('Cache has changed:', newCache);
            } catch (error) {
                error
                // console.error('Error setting cache in localforage', error);
            }
        },
        { deep: true }
    );

    app.mount('#app');
});